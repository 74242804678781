/* tslint:disable */
/* eslint-disable */
/**
 * Groenhart API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0.39.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ActivateAccountApiRequestModel,
  ActivateAccountApiResponseModel,
  MemberBaseApiModel,
  ProblemDetails,
  ResultApiModel,
  ValidateDebtorNumberResponseModel,
} from '../models';
import {
    ActivateAccountApiRequestModelFromJSON,
    ActivateAccountApiRequestModelToJSON,
    ActivateAccountApiResponseModelFromJSON,
    ActivateAccountApiResponseModelToJSON,
    MemberBaseApiModelFromJSON,
    MemberBaseApiModelToJSON,
    ProblemDetailsFromJSON,
    ProblemDetailsToJSON,
    ResultApiModelFromJSON,
    ResultApiModelToJSON,
    ValidateDebtorNumberResponseModelFromJSON,
    ValidateDebtorNumberResponseModelToJSON,
} from '../models';

export interface MembersActivateAccountPostRequest {
    activateAccountApiRequestModel?: ActivateAccountApiRequestModel;
}

export interface MembersMeDeleteRequest {
    refreshToken?: string;
}

export interface MembersValidateDebtorNumberGetRequest {
    debtorNumber?: number;
}

export interface MembersValidateEmailAddressGetRequest {
    emailAddress?: string;
}

/**
 * 
 */
export class MembersApi extends runtime.BaseAPI {

    /**
     */
    async membersActivateAccountPostRaw(requestParameters: MembersActivateAccountPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ActivateAccountApiResponseModel>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/Members/ActivateAccount`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ActivateAccountApiRequestModelToJSON(requestParameters.activateAccountApiRequestModel),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ActivateAccountApiResponseModelFromJSON(jsonValue));
    }

    /**
     */
    async membersActivateAccountPost(requestParameters: MembersActivateAccountPostRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ActivateAccountApiResponseModel> {
        const response = await this.membersActivateAccountPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async membersMeDeleteRaw(requestParameters: MembersMeDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ResultApiModel>> {
        const queryParameters: any = {};

        if (requestParameters.refreshToken !== undefined) {
            queryParameters['refreshToken'] = requestParameters.refreshToken;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/Members/Me`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ResultApiModelFromJSON(jsonValue));
    }

    /**
     */
    async membersMeDelete(requestParameters: MembersMeDeleteRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ResultApiModel> {
        const response = await this.membersMeDeleteRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async membersMeGetRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MemberBaseApiModel>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/Members/Me`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MemberBaseApiModelFromJSON(jsonValue));
    }

    /**
     */
    async membersMeGet(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MemberBaseApiModel> {
        const response = await this.membersMeGetRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async membersValidateDebtorNumberGetRaw(requestParameters: MembersValidateDebtorNumberGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ValidateDebtorNumberResponseModel>> {
        const queryParameters: any = {};

        if (requestParameters.debtorNumber !== undefined) {
            queryParameters['debtorNumber'] = requestParameters.debtorNumber;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/Members/ValidateDebtorNumber`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ValidateDebtorNumberResponseModelFromJSON(jsonValue));
    }

    /**
     */
    async membersValidateDebtorNumberGet(requestParameters: MembersValidateDebtorNumberGetRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ValidateDebtorNumberResponseModel> {
        const response = await this.membersValidateDebtorNumberGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async membersValidateEmailAddressGetRaw(requestParameters: MembersValidateEmailAddressGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ResultApiModel>> {
        const queryParameters: any = {};

        if (requestParameters.emailAddress !== undefined) {
            queryParameters['emailAddress'] = requestParameters.emailAddress;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/Members/ValidateEmailAddress`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ResultApiModelFromJSON(jsonValue));
    }

    /**
     */
    async membersValidateEmailAddressGet(requestParameters: MembersValidateEmailAddressGetRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ResultApiModel> {
        const response = await this.membersValidateEmailAddressGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
