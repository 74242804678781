/* tslint:disable */
/* eslint-disable */
/**
 * Groenhart API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0.39.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const ShipmentApiModelDeliveryTime = {
    Unknown: 'Unknown',
    Direct: 'Direct',
    Delayed: 'Delayed',
    NoDelivery: 'NoDelivery',
    StoreOnly: 'StoreOnly'
} as const;
export type ShipmentApiModelDeliveryTime = typeof ShipmentApiModelDeliveryTime[keyof typeof ShipmentApiModelDeliveryTime];


export function ShipmentApiModelDeliveryTimeFromJSON(json: any): ShipmentApiModelDeliveryTime {
    return ShipmentApiModelDeliveryTimeFromJSONTyped(json, false);
}

export function ShipmentApiModelDeliveryTimeFromJSONTyped(json: any, ignoreDiscriminator: boolean): ShipmentApiModelDeliveryTime {
    return json as ShipmentApiModelDeliveryTime;
}

export function ShipmentApiModelDeliveryTimeToJSON(value?: ShipmentApiModelDeliveryTime | null): any {
    return value as any;
}

