/* tslint:disable */
/* eslint-disable */
/**
 * Groenhart API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0.39.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { OrderLineApiModelDeliveryTime } from './OrderLineApiModelDeliveryTime';
import {
    OrderLineApiModelDeliveryTimeFromJSON,
    OrderLineApiModelDeliveryTimeFromJSONTyped,
    OrderLineApiModelDeliveryTimeToJSON,
} from './OrderLineApiModelDeliveryTime';

/**
 * 
 * @export
 * @interface OrderLineApiModel
 */
export interface OrderLineApiModel {
    /**
     * 
     * @type {number}
     * @memberof OrderLineApiModel
     */
    id?: number | null;
    /**
     * 
     * @type {number}
     * @memberof OrderLineApiModel
     */
    quantity?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof OrderLineApiModel
     */
    insufficientStock?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof OrderLineApiModel
     */
    totalInclVat?: number | null;
    /**
     * 
     * @type {number}
     * @memberof OrderLineApiModel
     */
    totalExclVat?: number | null;
    /**
     * 
     * @type {string}
     * @memberof OrderLineApiModel
     */
    deliveryText?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderLineApiModel
     */
    sku?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderLineApiModel
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderLineApiModel
     */
    type?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderLineApiModel
     */
    mainImage?: string | null;
    /**
     * 
     * @type {OrderLineApiModelDeliveryTime}
     * @memberof OrderLineApiModel
     */
    deliveryTime?: OrderLineApiModelDeliveryTime;
    /**
     * 
     * @type {string}
     * @memberof OrderLineApiModel
     */
    brandName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderLineApiModel
     */
    mainCategory?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof OrderLineApiModel
     */
    disabledForDebtorNumber?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof OrderLineApiModel
     */
    unitQuantity?: number | null;
    /**
     * 
     * @type {string}
     * @memberof OrderLineApiModel
     */
    unit?: string | null;
    /**
     * 
     * @type {number}
     * @memberof OrderLineApiModel
     */
    priceExclVat?: number | null;
    /**
     * 
     * @type {number}
     * @memberof OrderLineApiModel
     */
    listPriceExclVat?: number | null;
    /**
     * 
     * @type {number}
     * @memberof OrderLineApiModel
     */
    priceInclVat?: number | null;
    /**
     * 
     * @type {number}
     * @memberof OrderLineApiModel
     */
    listPriceInclVat?: number | null;
    /**
     * 
     * @type {number}
     * @memberof OrderLineApiModel
     */
    requiredSaleQuantity?: number | null;
}

/**
 * Check if a given object implements the OrderLineApiModel interface.
 */
export function instanceOfOrderLineApiModel(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function OrderLineApiModelFromJSON(json: any): OrderLineApiModel {
    return OrderLineApiModelFromJSONTyped(json, false);
}

export function OrderLineApiModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrderLineApiModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'quantity': !exists(json, 'quantity') ? undefined : json['quantity'],
        'insufficientStock': !exists(json, 'insufficientStock') ? undefined : json['insufficientStock'],
        'totalInclVat': !exists(json, 'totalInclVat') ? undefined : json['totalInclVat'],
        'totalExclVat': !exists(json, 'totalExclVat') ? undefined : json['totalExclVat'],
        'deliveryText': !exists(json, 'deliveryText') ? undefined : json['deliveryText'],
        'sku': !exists(json, 'sku') ? undefined : json['sku'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'type': !exists(json, 'type') ? undefined : json['type'],
        'mainImage': !exists(json, 'mainImage') ? undefined : json['mainImage'],
        'deliveryTime': !exists(json, 'deliveryTime') ? undefined : OrderLineApiModelDeliveryTimeFromJSON(json['deliveryTime']),
        'brandName': !exists(json, 'brandName') ? undefined : json['brandName'],
        'mainCategory': !exists(json, 'mainCategory') ? undefined : json['mainCategory'],
        'disabledForDebtorNumber': !exists(json, 'disabledForDebtorNumber') ? undefined : json['disabledForDebtorNumber'],
        'unitQuantity': !exists(json, 'unitQuantity') ? undefined : json['unitQuantity'],
        'unit': !exists(json, 'unit') ? undefined : json['unit'],
        'priceExclVat': !exists(json, 'priceExclVat') ? undefined : json['priceExclVat'],
        'listPriceExclVat': !exists(json, 'listPriceExclVat') ? undefined : json['listPriceExclVat'],
        'priceInclVat': !exists(json, 'priceInclVat') ? undefined : json['priceInclVat'],
        'listPriceInclVat': !exists(json, 'listPriceInclVat') ? undefined : json['listPriceInclVat'],
        'requiredSaleQuantity': !exists(json, 'requiredSaleQuantity') ? undefined : json['requiredSaleQuantity'],
    };
}

export function OrderLineApiModelToJSON(value?: OrderLineApiModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'quantity': value.quantity,
        'insufficientStock': value.insufficientStock,
        'totalInclVat': value.totalInclVat,
        'totalExclVat': value.totalExclVat,
        'deliveryText': value.deliveryText,
        'sku': value.sku,
        'name': value.name,
        'type': value.type,
        'mainImage': value.mainImage,
        'deliveryTime': OrderLineApiModelDeliveryTimeToJSON(value.deliveryTime),
        'brandName': value.brandName,
        'mainCategory': value.mainCategory,
        'disabledForDebtorNumber': value.disabledForDebtorNumber,
        'unitQuantity': value.unitQuantity,
        'unit': value.unit,
        'priceExclVat': value.priceExclVat,
        'listPriceExclVat': value.listPriceExclVat,
        'priceInclVat': value.priceInclVat,
        'listPriceInclVat': value.listPriceInclVat,
        'requiredSaleQuantity': value.requiredSaleQuantity,
    };
}

